<template>
  <div class="mt-5">
    <div class="d-flex align-center py-3 mb-5">
      <v-sheet width="100%" max-width="450">
        <v-text-field
          append-icon="mdi-magnify"
          class="custom-shadow"
          placeholder="Search"
          v-model="search"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>
      </v-sheet>

      <v-spacer></v-spacer>

      <v-btn 
        @click="createNewTerm()"
        class="box-shadow mr-1"
        color="primary"
        small
        text 
      >Add New</v-btn>

      <v-btn 
        @click="getCannedResponses(type)" 
        :loading="status.getting"
        icon
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>

    <preloader v-if="status.getting" />

    <v-alert v-if="!status.getting && !responses.length" type="info" border="left" >
      No canned responses found.
    </v-alert>

    <v-card v-if="!status.getting  && responses.length" class="box-shadow" outlined>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th>Name</th>
              <th>Created</th>
              <th>Last Updated</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <v-fade-transition tag="tbody" group>
            <tr
              v-for="response in confResponses"
              :key="response.id"
            >
              <td>{{ response.name }}</td>
              <td class="caption grey--text">{{ response.created | formatDate }}</td>
              <td class="caption grey--text">{{ response.updated || response.created | formatDate }}</td>
              <td class="text-right">
                <v-menu left offset-y>
                  <template #activator="{ on }">
                    <v-btn v-on="on" icon small>
                      <v-icon small>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="subtle" dense>
                    <v-list-item @click="editResponse(response)">
                      <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="updateResponseField({
                      response,
                      field: 'type',
                      value: type == 'request' ? 'order' : 'request',
                      message: `Moved canned response to ${type == 'request' ? 'orders' : 'requests'}`
                    })">
                      <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-arrow-right-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Move to {{ type == 'request' ? 'orders' : 'requests' }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="copyResponse(response)">
                      <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-content-duplicate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Copy to {{ type == 'request' ? 'orders' : 'requests' }}</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="confirmDelete(response)">
                      <v-list-item-icon class="mr-2">
                        <v-icon color="error" small>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="error--text">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </v-fade-transition>
        </template>
      </v-simple-table>
    </v-card>

    <!-- CREATE DIALOG -->
    <create-dialog ref="createDialog" />

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :message="`Delete canned response ${toDelete.name}?`"
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
      :deleting="status.deleting"
      :show="deleteDialog"
    />
  </div>
</template>

<script>
import { orderBy, omit } from 'lodash'
import { mapState, mapActions } from 'vuex'

import CreateDialog from './components/CreateResponseDialog.vue'

export default {
  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    CreateDialog
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      search: null,
      toDelete: {},
      deleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.canned_responses.status,
      responses: state => state.canned_responses.responses,
    }),

    type: function () {
      return this.$route.name == 'RequestCannedResponses' ? 'request' : 'order'
    },

    confResponses: function () {
      let responses = this.responses.filter(response => {
        if (this.search) {
          return response.name.toLowerCase().includes(this.search.toLowerCase())
        }
        return true
      })

      responses = responses.filter(response => {
        return this.type == 'request' ? response.type =='request' : response.type == 'order'
      })

      responses = orderBy(responses, 'name', 'asc')

      return responses
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('canned_responses', [
      'deleteCannedResponse',
      'updateResponseField',
      'getCannedResponses',
      'createResponse',
    ]),

    createNewTerm() {
      this.$refs.createDialog.showDialog()
    },

    editResponse(response) {
      this.$store.commit('canned_responses/setResponseData', response)
      this.$refs.createDialog.showDialog()
    },

    confirmDelete(response) {
      this.toDelete = response
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.deleteDialog = false
      this.toDelete = {}
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteCannedResponse(this.toDelete))
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    copyResponse(response) {
      let newResponse = Object.assign({}, omit(response, ['id', 'ref', 'type']))
      let newType = this.type == 'request' ? 'order' : 'request'
      this.$store.commit('canned_responses/setResponseData', newResponse)
      this.createResponse(newType)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.responses.length) 
      this.getCannedResponses(this.type)
  }
}
</script>