var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"d-flex align-center py-3 mb-5"},[_c('v-sheet',{attrs:{"width":"100%","max-width":"450"}},[_c('v-text-field',{staticClass:"custom-shadow",attrs:{"append-icon":"mdi-magnify","placeholder":"Search","hide-details":"","clearable":"","outlined":"","rounded":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"box-shadow mr-1",attrs:{"color":"primary","small":"","text":""},on:{"click":function($event){return _vm.createNewTerm()}}},[_vm._v("Add New")]),_c('v-btn',{attrs:{"loading":_vm.status.getting,"icon":""},on:{"click":function($event){return _vm.getCannedResponses(_vm.type)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),(_vm.status.getting)?_c('preloader'):_vm._e(),(!_vm.status.getting && !_vm.responses.length)?_c('v-alert',{attrs:{"type":"info","border":"left"}},[_vm._v(" No canned responses found. ")]):_vm._e(),(!_vm.status.getting  && _vm.responses.length)?_c('v-card',{staticClass:"box-shadow",attrs:{"outlined":""}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Created")]),_c('th',[_vm._v("Last Updated")]),_c('th',[_vm._v(" ")])])]),_c('v-fade-transition',{attrs:{"tag":"tbody","group":""}},_vm._l((_vm.confResponses),function(response){return _c('tr',{key:response.id},[_c('td',[_vm._v(_vm._s(response.name))]),_c('td',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm._f("formatDate")(response.created)))]),_c('td',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm._f("formatDate")(response.updated || response.created)))]),_c('td',{staticClass:"text-right"},[_c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"subtle",attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editResponse(response)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateResponseField({
                    response: response,
                    field: 'type',
                    value: _vm.type == 'request' ? 'order' : 'request',
                    message: ("Moved canned response to " + (_vm.type == 'request' ? 'orders' : 'requests'))
                  })}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right-circle")])],1),_c('v-list-item-title',[_vm._v("Move to "+_vm._s(_vm.type == 'request' ? 'orders' : 'requests'))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.copyResponse(response)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-duplicate")])],1),_c('v-list-item-title',[_vm._v("Copy to "+_vm._s(_vm.type == 'request' ? 'orders' : 'requests'))])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.confirmDelete(response)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1)],1)],1)],1)])}),0)]},proxy:true}],null,false,432094025)})],1):_vm._e(),_c('create-dialog',{ref:"createDialog"}),_c('confirm-delete',{attrs:{"message":("Delete canned response " + (_vm.toDelete.name) + "?"),"deleting":_vm.status.deleting,"show":_vm.deleteDialog},on:{"confirmed":function($event){return _vm.deleteConfirmed()},"cancel":function($event){return _vm.closeDeleteDialog()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }